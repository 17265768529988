import { APIMethods, APIMethodsGames, AppSettings, GeneralConstants } from '../app.config';
import {
  EXPORT_ALL_DATA_TO_EXCEL_FAILURE,
  EXPORT_ALL_DATA_TO_EXCEL_SUCCESS,
  SET_ACTIVE_CAMPAIGNS_PER_USER,
  SET_BET_TYPES,
  SET_BRANCHES,
  SET_ENUMS,
  SET_MARKETING_CAMPAIGNS,
  SET_PAYMENT_METHODS,
} from '../store/actions/metadata.actions';
import { CachingModel } from './coreModels';
import { SET_POOL_BET_TYPES } from 'src/app/jackpot/store/actions/sportsbook.actions';
import { SET_ACTIVE_CAMPAIGNS } from '../../bonus/store/actions/activeCampaigns.actions';
import { ONLINE_ACTION_FAILURE, ONLINE_ACTION_SUCCESS } from 'abs@core/store/actions/online.actions';
import { SET_EXPENSE_CATEGORIES } from 'src/app/expenses/store/actions/expenses.actions';
import { FormGroup } from '@angular/forms';

export class BaseRequest {
  constructor(public UserToken = '', public Language = 'en') {}
}

export class BaseRequestDynamic {
  constructor(public methodGroup = '', public methodName = '') {}
}

export class GetOnlineBranchesRequest extends BaseRequestDynamic {
  constructor() {
    super('Sport', 'GetOnlineBranches');
  }
}

export class UsersAutocompleteRequest {
  constructor(public usernameOrId = '', public isOnline: boolean = null) {}
}

export class GetActiveCampaignsPerUserRequestModel {
  constructor(public userId: number) {}
}

export class ActionRequest {
  constructor(
    public Url = '',
    public MethodName = '',
    public SuccessFullActionToBeCalled = '',
    public Payload: any = null
  ) {}
}

export class ExportAllRequest {
  constructor(
    public Url = '',
    public MethodName = '',
    public Payload: any = null,
    public SuccessFullActionToBeCalled = EXPORT_ALL_DATA_TO_EXCEL_SUCCESS,
    public FailureActionToBeCalled = EXPORT_ALL_DATA_TO_EXCEL_FAILURE
  ) {}
}

export class DataRequest {
  constructor(
    public Url = '',
    public MethodName = '',
    public SuccessFullActionToBeCalled = '',
    public Payload: any = null,
    public Caching: CachingModel = null,
    public TimeOut: number = 5000
  ) {}
}

export class PostActionRequest {
  constructor(
    public Url = '',
    public MethodName = '',
    public Payload: any = null,
    public SuccessFullActionToBeCalled = '',
    public FailureActionToBeCalled = '',
    public successMsg = '',
    public errorMsg = ''
  ) {}
}

export class PostGeneralActionRequest extends PostActionRequest {
  constructor(
    public request = null,
    public methodName = null,
    public successAction = ONLINE_ACTION_SUCCESS,
    public failureAction = ONLINE_ACTION_FAILURE,
    url = AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY
  ) {
    super(url, methodName, request, successAction, failureAction);
  }
}

export class PaginationAndSort {
  constructor(public skip: number = null, public take: number = null, public sort: SortObject[] = null) {}
}

export class SortObject {
  constructor(public sortBy: string = null, public desc: boolean = null) {}
}


export interface Sort {
  [key: string]: 'asc' | 'desc';
}

export class GetBranchesPerUser extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_BRANCHES,
      SET_BRANCHES,
      request,
      { duration: GeneralConstants.ONE_DAY_DURATION, key: '#Branches-Per-User' }
    );
  }
}

export class GetBetTypes extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_BET_TYPES,
      SET_BET_TYPES,
      request,
      { duration: GeneralConstants.ONE_DAY_DURATION, key: 'Bet-Types' }
    );
  }
}

export class GetMarketingCampaigns extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_MARKETING_CAMPAIGNS,
      SET_MARKETING_CAMPAIGNS,
      request,
      { duration: GeneralConstants.ONE_DAY_DURATION, key: 'Marketing-Campaigns' }
    );
  }
}

export class GetPoolBetTypes extends DataRequest {
  constructor(public request = { IsPool: true }) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_BET_TYPES,
      SET_POOL_BET_TYPES,
      request,
      { duration: GeneralConstants.ONE_DAY_DURATION, key: 'Pool-Bet-Types' }
    );
  }
}

export class GetPaymentMethods extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_PAYMENT_METHODS,
      SET_PAYMENT_METHODS,
      request,
      { duration: GeneralConstants.ONE_DAY_DURATION, key: 'payment-methods' }
    );
  }
}

export class GetEnums extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_ENUMS,
      SET_ENUMS,
      request,
      { duration: GeneralConstants.ONE_DAY_DURATION, key: 'Enums' }
    );
  }
}

export class GetExpensesCategories extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_EXPENSES_CATEGORIES,
      SET_EXPENSE_CATEGORIES,
      request
    );
  }
}

export class GetActiveCampaignsRequest extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_ACTIVE_CAMPAIGNS,
      SET_ACTIVE_CAMPAIGNS,
      request
    );
  }
}

export class GetSupportedLanguages extends DataRequest {
  constructor(public successAction = null, public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_SUPPORTED_LANGUAGES,
      successAction,
      request
    );
  }
}

export class GetSupportedLanguagesRequest {
  constructor(public requestedBranchId: number) {}
}

export class GetActiveCampaignsPerUserRequest extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_ACTIVE_CAMPAIGNS,
      SET_ACTIVE_CAMPAIGNS_PER_USER,
      request
    );
  }
}

export interface BaseRequestInterface {
  apiUrl: string;
  method: string;
  body?: any;
}

export interface BaseActionInterface {
  actionName: any;
  keys: string[];
  mapResult?: { [key: number]: MapResultInterface } /*see an example in the Branch Summary report
  component OnChangeSelection of reportsBy cause change in configuration for other filter*/;
  /**
   * updateOnChange -> what other form controls do we want to update on change of this input value
   * @param filtersForm
   */
  updateOnChange?: (filtersForm: FormGroup) => void;
}

export type MapResultInterface = { [key: string]: any };
