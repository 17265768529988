import { createAction, props } from '@ngrx/store';
import { FailAction, Filtering } from 'src/app/sport-betting/common/models';
import { DefaultBetType, UpdateDefaultBetType } from '../../models';

const SB_GET_DEFAULT_BET_TYPES = '[SBDBT] Get default bet types';
const SB_SET_DEFAULT_BET_TYPES = '[SBDBT] Set default bet types';
const SB_UPDATE_DEFAULT_BET_TYPE = '[SBDBT] Update default bet type';
const SB_DELETE_DEFAULT_BET_TYPE = '[SBDBT] Delete default bet type';
const SB_DELETE_DEFAULT_BET_TYPE_SUCCESS = '[SBDBT] Delete default bet type SUCCESS';
const SB_UPDATE_DEFAULT_BET_TYPE_SUCCESS = '[SBDBT] Update default bet type SUCCESS';

const FAILURE = '[SBDBT] Failure';

export const SbGetDefaultBetTypes = createAction(SB_GET_DEFAULT_BET_TYPES, props<{ payload: Filtering }>());
export const SbSetDefaultBetTypes = createAction(SB_SET_DEFAULT_BET_TYPES, props<{
  payload: {
    data: Array<DefaultBetType>,
    totalCount: number;
  }
}>());
export const SbDeleteDefaultBetType = createAction(SB_DELETE_DEFAULT_BET_TYPE, props<{ payload: number }>());
export const SbDeleteDefaultBetTypesSuccess = createAction(SB_DELETE_DEFAULT_BET_TYPE_SUCCESS);
export const SbUpdateDefaultBetType = createAction(SB_UPDATE_DEFAULT_BET_TYPE, props<{ payload: UpdateDefaultBetType }>());
export const SbUpdateDefaultBetTypeSuccess = createAction(SB_UPDATE_DEFAULT_BET_TYPE_SUCCESS, props<{ payload: UpdateDefaultBetType }>());

export const DefaultBetTypesFailure = createAction(FAILURE, props<FailAction>());