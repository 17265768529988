<!-- TODO: modal do not accept "modal" -->
<p-dialog [modal]="true"  header="Select Timezone" [style]="{height: '20vw', width:'30vw'}" [(visible)]="timeZoneDialogVisible"
          [responsive]="true" [positionLeft]="0" [positionTop]="50">
  <p-dropdown appendTo="body" class="fluid" (onChange)="onChangeTImeZone($event)" [options]="timeZonesList | async"
              [(ngModel)]="selectedTimeZone" [filter]="true"></p-dropdown>
</p-dialog>

<div [ngClass]="{'layout-inline-menu': true, 'layout-inline-menu-active':appMain.activeInlineProfile}" *ngIf="!appMain.isMobile() && (appMain.isStatic() || appMain.isSlim() || appMain.isSidebar())">
    <a class="layout-inline-menu-action flex p-dir-row align-items-center" (click)="appMain.activeInlineProfile = !appMain.activeInlineProfile">
        <img src="assets/layout/images/profile-image.png" alt="avatar" style="width: 44px; height: 44px;">
        <span class="layout-inline-menu-text flex flex-column ml-2">
          <span>{{ (userData | async).userName }} {{ (userData | async).userId }}</span>
        </span>
        <i class="layout-inline-menu-icon pi pi-angle-down ml-auto"></i>
    </a>

    <ul class="layout-inline-menu-action-panel" [@inline]="appMain.activeInlineProfile ? 'visibleAnimated' : 'hiddenAnimated'">
        <li class="layout-inline-menu-action-item">
          <a class="flex flex-row align-items-center" href="#" (click)="appMain.onTopbarSubItemClick($event, 'logout')">
            <i class="pi pi-power-off pi-fw"></i>
            <span>{{ 'Logout' | translate }}</span>
          </a>
        </li>
        <li class="layout-inline-menu-action-item">
            <a class="flex flex-row align-items-center">
                <i class="fas fa-user-clock"></i>
                <span>{{selectedTimeZone?.label}}</span>
            </a>
        </li>
      <li class="layout-inline-menu-action-item">
            <a class="flex flex-row align-items-center" (click)="appMain.onTopbarSubItemClick($event, 'clearCache')">
                <i class="fas fa-eraser"></i>
                <span>{{'Clear_Cache' | translate}}</span>
<!--                <span [ngStyle]="userDataStyle">{{(userData | async).firstName + ' ' + (userData | async).lastName}}</span>-->
            </a>
        </li>
      <li class="layout-inline-menu-action-item">
          <a class="flex flex-row align-items-center" (click)="appMain.onTopbarSubItemClick($event, 'syncUserSettings')">
            <i class="fas fa-sync"></i>
            <span>{{'Refresh_User_Settings' | translate}}</span>
          </a>
        </li>
<!--      <li class="layout-inline-menu-action-item">-->
<!--        <a class="flex flex-row align-items-center" (click)="authorizationService.onConfigButtonClick($event)">-->
<!--          <i class="fas fa-sync"></i>-->
<!--          <span>{{'Refresh_User_Settings' | translate}}</span>-->
<!--        </a>-->
<!--      </li>-->
<!--      <li class="layout-inline-menu-action-item">-->
<!--            <a class="flex flex-row align-items-center">-->
<!--                <i class="pi pi-cog pi-fw"></i>-->
<!--                <span>Settings</span>-->
<!--            </a>-->
<!--        </li>-->
<!--        <li class="layout-inline-menu-action-item">-->
<!--            <a class="flex flex-row align-items-center">-->
<!--                <i class="pi pi-user pi-fw"></i>-->
<!--                <span>Profile</span>-->
<!--            </a>-->
<!--        </li>-->
    </ul>
</div>
